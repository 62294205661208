import { useCallback, useState } from "react";
import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { FontPicker } from "./FontPicker";
import { ActiveFont, useElementFonts } from "../../hooks/useElementFonts";
import { ActiveView } from "../../hooks/useActiveView";
import { LetteringFloatingButton } from "../floatingButton/LetteringFloatingButton";

type LetteringFontProps = {
  activeView: ActiveView;
  elementIndex: number;
};

export const LetteringFont: React.FC<LetteringFontProps> = ({
  activeView,
  elementIndex,
}) => {
  const [availableFonts, activeFont, setFont] = useElementFonts(
    activeView,
    elementIndex
  );

  const [isPickerVisible, setPickerVisible] = useState(false);

  const showPicker = useCallback(() => {
    setPickerVisible(true);
  }, [setPickerVisible]);

  const closePicker = useCallback(() => {
    setPickerVisible(false);
  }, [setPickerVisible]);

  const onFontChange = useCallback(
    (font: ActiveFont | undefined) => {
      setFont(font);
    },
    [setFont]
  );

  if (availableFonts.length <= 0 || !activeFont) {
    return null;
  }

  return (
    <>
      <PersonalizeEditItem size="half">
        <LetteringFloatingButton font={activeFont} onClick={showPicker} />
      </PersonalizeEditItem>

      <FontPicker
        key={activeFont.id}
        isPickerVisible={isPickerVisible}
        closePicker={closePicker}
        initialFont={activeFont}
        onFontChange={onFontChange}
        availableFonts={availableFonts}
      />
    </>
  );
};
