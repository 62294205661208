'use strict';

/* global location */

var cart = require('../cart/cart');
var updateMiniCart = true;

/**
 * copy of base/components/miniCart with accel miniCart updates
 */
function base() {
    const isMobile = window.matchMedia('(max-width: 991.98px)');
    cart();

    $('body').on('click', '.js-close-minicart', function () {
        $('.minicart .popover').removeClass('show');
    });

    $('body').on('click', '.js-return-false', function () {
        if (isMobile.matches && $('.minicart .minicart-quantity').data('cartCount') > 0) {
            return false;
        }
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal).data('cartCount', count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        // cartful zero count fix
        let $minicartItem = $('.minicart [data-num-of-items]');
        let cartfullCount = 0;
        if ($minicartItem && $minicartItem.length > 0) {
            cartfullCount = parseInt($minicartItem.data('num-of-items'));
        }
        var count = parseInt($('.minicart .minicart-quantity').text(), 10) || cartfullCount;

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $('.minicart-quantity').text($(data).find('.num-of-items-badge').data('num-of-items'));
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('body').on('keydown', function (e) {
        if (e.key === 'c' && e.altKey) {
            $('.utility-items-wrap .mini-cart-link').focus();
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        updateMiniCart = true;
        if ((event.type === 'focusout' && $('.minicart').has(event.relatedTarget).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
        $('.minicart').trigger('focusin');
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                try {
                    if (data.isEnabled) {
                        window.dataLayer.push((JSON.parse(data.dataLayerObj)));
                    }
                } catch (error) {
                    window.console.error('\nminiCart.js: error in {0}', error);
                }
                // displayMessageAndRemoveFromCart(data);
                $('#moveToWishListModal').modal('hide');
                $.spinner().stop();
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    base: base,
    moveToWishlist: moveToWishlist
};
